import axios from "axios";
import {
  aboutUsFunc,
  bannersDataFunc,
  blogsHomePageFunc,
  carouselImagesFunc,
  catListFunc,
  contactsFunc,
  getBlogsAllNewsFunc,
  isLoadingFunc,
  mostSelListFunc,
  newsIDFunc,
  partenrsDataFunc,
  partersAboutFunc,
  productIDfunc,
  productsListFunc,
  sevenCategoriesFunc,
} from "../redux/MainReducer";

// Базовоя api
const baseURL =
  "https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/api/";

// Отображения продуктов в самом начале страницы(BANERS)

export const getBannersData = () => async (dispatch) => {
  try {
    const response = await axios.get(baseURL + "banners/");
    dispatch(bannersDataFunc(response.data));
  } catch (error) {
    console.error(error);
    dispatch(bannersDataFunc([]));
  }
};

// Карусел на главной странице

export const getCarouselImages = () => async (dispatch) => {
  try {
    const response = await axios.get(baseURL + "carousel-images/");
    dispatch(carouselImagesFunc(response.data));
    // console.log(response);
    setTimeout(() => {
      dispatch(isLoadingFunc());
    }, 1000);
  } catch (error) {
    console.error(error);
    dispatch(carouselImagesFunc([]));
  }
};

// Отображение фото партнеров и на главной странице и на собственной

export const getPartnersData = () => async (dispatch) => {
  try {
    const response = await axios.get(baseURL + "partners/az/");
    //    console.log(response?.data?.partners);

    dispatch(partenrsDataFunc(response?.data?.partners));
  } catch (error) {
    console.error(error);
    dispatch(partenrsDataFunc([]));
  }
};

// Отображения 7 продуктов по категориям на главной странице

export const getSevenCatecories = (currLang) => async (dispatch) => {
  if (currLang) {
    try {
      // Если язык 'kz' или 'uz', заменяем на 'en'
      const lang = ["kz", "uz"].includes(currLang) ? "en" : currLang;
      const response = await axios.get(
        `${baseURL}categories/homepage/${lang}/`
      );
      dispatch(sevenCategoriesFunc(response.data));
    } catch (error) {
      console.error(error);
      dispatch(sevenCategoriesFunc([]));
    }
  }
};

// Отображения контактов и на футоре и на отдельной странице контакты

export const getContacts = (currLang) => async (dispatch) => {
  if (currLang) {
    try {
      // Если язык 'kz' или 'uz', заменяем на 'en'
      const lang = ["kz", "uz"].includes(currLang) ? "en" : currLang;
      const response = await axios.get(`${baseURL}contacts/${lang}/`);
      // console.log(response);

      dispatch(contactsFunc(response.data));
    } catch (error) {
      console.log(error);
    }
  }
};

// Отображения новостей на главной странице

export const getBlogsHomePage = (currLang) => async (dispatch) => {
  if (currLang) {
    try {
      // Если язык 'kz' или 'uz', заменяем на 'en'
      const lang = ["kz", "uz"].includes(currLang) ? "en" : currLang;
      const response = await axios.get(`${baseURL}blogs/homepage/${lang}/`);
      // console.log(response.data);

      dispatch(blogsHomePageFunc(response.data));
    } catch (error) {
      console.error(error);
      dispatch(blogsHomePageFunc([]));
    }
  }
};

// Отображения новостей на странице новостей

export const getBlogsAllNews = (currLang) => async (dispatch) => {
  try {
    const lang = ["kz", "uz"].includes(currLang) ? "en" : currLang;
    const response = await axios.get(`${baseURL}blogs/${lang}/`);

    dispatch(getBlogsAllNewsFunc(response?.data));
    dispatch(isLoadingFunc());
  } catch (error) {
    console.error(error);
    dispatch(getBlogsAllNewsFunc([]));
  }
};

// Отображения новости на синг странице

export const getNewsID = (id) => async (dispatch) => {
  if (id) {
    try {
      const response = await axios.get(`${baseURL}blogs/${id}/az/`);
      dispatch(newsIDFunc(response.data));
    } catch (error) {
      console.log(error);
    }
  }
};

// Отображения списка категорий

export const getCatList = (currLang) => async (dispatch) => {
  if (currLang) {
    try {
      const lang = ["kz", "uz"].includes(currLang) ? "en" : currLang;
      const response = await axios.get(`${baseURL}categories/${lang}/`);

      dispatch(catListFunc(response.data));
    } catch (error) {
      console.log(error);
      dispatch(catListFunc([]));
    }
  }
};

// Отображения списка товаров

export const getProductsList = () => async (dispatch) => {
  try {
    const response = await axios.get(baseURL + "products/");
    dispatch(productsListFunc(response.data));
    dispatch(isLoadingFunc());
  } catch (error) {
    console.log(error);
    dispatch(isLoadingFunc());
  }
};

// Отображения списка товаров по категориям и используем productsListFunc снова
export const getCategoryIDlist = (id) => async (dispatch) => {
  if (id) {
    try {
      const response = await axios.get(`${baseURL}products/category/${id}`);
      dispatch(productsListFunc(response.data));
    } catch (error) {
      console.log(error);
    }
  }
};

// Отображения списка товаров по ПОДкатегориям и используем productsListFunc снова
export const getSubCategoryIDlist = (id) => async (dispatch) => {
  if (id) {
    try {
      const response = await axios.get(`${baseURL}products/subcategory/${id}/`);
      dispatch(productsListFunc(response.data));
    } catch (error) {
      console.log(error);
    }
  }
};

// Отображение данных товара

export const getProductID = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`${baseURL}products/${id}/az/`);
    dispatch(productIDfunc(response?.data));
    dispatch(isLoadingFunc());
  } catch (error) {
    console.error(error);
  }
};

// Отображение много продоваемых товаров

export const getMostSelList = () => async (dispatch) => {
  try {
    const response = await axios.get(baseURL + "most-selling-products/");
    dispatch(mostSelListFunc(response.data));
  } catch (error) {}
};

// Отображение инфо и работников компании
export const getAboutUs = (currLang) => async (dispatch) => {
  if (currLang) {
    try {
      const lang = ["kz", "uz"].includes(currLang) ? "en" : currLang;
      const response = await axios.get(`${baseURL}about-us/${lang}/`);
      // console.log(response.data);

      dispatch(aboutUsFunc(response.data));
      dispatch(isLoadingFunc());
    } catch (error) {
      console.log(error);
      dispatch(aboutUsFunc([]));
      dispatch(isLoadingFunc());
    }
  }
};

export const getPartnersAbout = (currLang) => async (dispatch) => {
  if (currLang) {
    try {
      const lang = ["kz", "uz"].includes(currLang) ? "en" : currLang;

      const response = await axios.get(`${baseURL}partners/${lang}/`);
      dispatch(partersAboutFunc(response.data));
      dispatch(isLoadingFunc());
    } catch (error) {
      console.error(error);
      dispatch(aboutUsFunc([]));
      dispatch(isLoadingFunc());
    }
  }
};
