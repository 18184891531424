import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBlogsAllNews } from "../action/MainAction";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Loading from "./Loading";
import {
  getBlogsAllNewsFunc,
  isLoadingFunc,
  isLoadingStart,
} from "../redux/MainReducer";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import axios from "axios";
import rightVector from '../images/rightVector.svg'
import leftVector from '../images/leftVector.svg'

const News = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.Data.loading);

  // Вызов переменной содержащий данные о партеров
  const blogsAllNewsValue = useSelector(
    (state) => state.Data.blogsAllNewsValue
  );
  // Используем код языка для отображения url www.site.com/en/...
  const lang = i18next?.language.toLowerCase();

  // Вызов api/blogs/
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(isLoadingStart()); // Запускаем загрузку
    if (i18next.language !== "dev") {
      dispatch(getBlogsAllNews(lang));
    }
    
  }, [dispatch, i18next.language]);

  //  Получаемый дата объект присвоение к массиву
  const blogsArr = blogsAllNewsValue.results;

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(blogsAllNewsValue?.count / 12);

  // Смена показа продуктов при нажатии на числа пагинации
  const handlePageClick = async (page) => {
    dispatch(isLoadingStart()); // Устанавливаем загрузку при нажатии на числа пагинации

    window.scrollTo({ top: 0, behavior: "smooth" }); // Скроллим наверх
    setCurrentPage(page); // Устанавливаем текущую страницу

    try {
      if (page === 1) {
        setTimeout(() => {
          dispatch(getBlogsAllNews(i18next.language.toLowerCase())); // Если первая страница, вызываем готовый action
        }, 1000);
        dispatch(isLoadingStart()); // Устанавливаем загрузку
      } else if (blogsAllNewsValue?.next || blogsAllNewsValue?.previous) {
        // Если есть следующая или предыдущая страница, запрашиваем её
        const response = await axios.get(
          `https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/api/blogs/${lang}/?page=${page}`
        );
        setTimeout(() => {
          dispatch(getBlogsAllNewsFunc(response.data));
        }, 1000);
      }
    } catch (error) {
      console.error("Ошибка при загрузке:", error);
      // Если ошибка при нажатии при вызове, вызываем готовый action
      dispatch(getBlogsAllNewsFunc([]));
    } finally {
      setTimeout(() => {
        dispatch(isLoadingFunc()); // Устанавливаем loading в false через 2 секунды
      }, 1000);
      console.log("Загрузка завершена"); // Отключаем загрузку
    }
  };

  return (
    <div className="relative overflow-hidden">
      <div className='absolute top-0 -z-10 hidden sm:block sm:-right-36 lg:right-0'>
        <img src={rightVector} alt="" />
      </div>
      <div className='absolute top-0 -z-10 hidden sm:block sm:-left-36 lg:left-0'>
        <img src={leftVector} alt="" />
      </div>  
      {loading ? (
        <Loading />
      ) : (
        <div data-aos="zoom-in-up" className="max-w-[1240px] mx-auto my-16 text-white px-5">
          <div className="grid grid-cols-1 md:grid-cols-2 place-items-center gap-x-6 gap-y-[30px] select-none">
            {blogsArr?.map((data, i) => (
              <div className="max-w-[588px] p-5 bg-gradient-to-b from-[#FFFFFF]/40 to-[#A6A6A6]/10 rounded-lg" key={i}>
                <div className="max-w-[548px] h-[244px]">
                  <img
                    src={data?.image}
                    alt={`Buz Group New About ${data?.title}`}
                    className="w-full h-full"
                  />
                </div>
                <h6 className="text-xl h-[56px] mt-7 font-taviraj">
                  {data?.title}
                </h6>
                <p
                  className="mb-4 mt-2 leading-6 font-dm "
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(data?.content).substring(0, 140) + " . . .",
                  }}
                ></p>

                <Link to={`/${lang}/singlenewspage/${data?.id}`}>
                  <button className="font-semibold text-lightGreen hover:text-white hover:px-3 py-2 hover:bg-darkGreen ease-in-out duration-300 rounded-lg">
                    {t("viewDetails")} →
                  </button>
                </Link>
              </div>
            ))}
          </div>
          {/* Pagination */}
          <ResponsivePagination
            current={currentPage}
            total={totalPages}
            onPageChange={(page) => handlePageClick(page)}
          />
        </div>
      )}
    </div>
  );
};

export default News;
