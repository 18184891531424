import React, { useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, EffectFade } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { getCarouselImages } from "../action/MainAction";
import Loading from "./Loading";
import { isLoadingStart } from "../redux/MainReducer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

// import Map from '../images/map2.png'
import worldMap from '../videos/worldMap.webm'

const Hero = () => {

  const dispatch = useDispatch();

  // Вызов /api/carousel-images/
  useEffect(() => {
    dispatch(isLoadingStart());
    dispatch(getCarouselImages());
  }, [dispatch]);

  // Вызов переменной содержащий фото для карусели
  const carouselImages = useSelector((state) => state.Data.carouselImagesValue);
  const loading = useSelector((state) => state.Data.loading);


  return (
    <>
    {loading? <Loading/>:

    <div className="w-full relative max-h-[740px]">
      <div className="absolute text-white bottom-3 pr-6 sm:pr-0 sm:top-32 md:top-40 left-6 lg:left-32">
        <h1 className="font-taviraj font-medium text-xs sm:text-lg md:text-2xl lg:text-3xl xl:text-5xl">Buz Group Şəbəkəmiz</h1>
        <p className="w-full sm:max-w-[200px] md:max-w-[340px] xl:max-w-[438px] font-dm text-[10px] sm:text-xs md:text-base lg:text-lg xl:text-xl leading-4 md:leading-7 md:mt-4 lg:mt-7 xl:mt-11">Filiallarımızın yerləşdiyi ölkələr ilə biz dünya miqyasında xidmətlərimizi genişləndiririk. 
          Hər bir regionda müştərilərimizin ehtiyaclarına uyğun yüksək keyfiyyətli həllər təqdim edirik. 
          Harada olmağınızdan asılı olmayaraq, qlobal şəbəkəmizlə yanınızdayıq.
        </p>
      </div>
      <video src={worldMap} autoPlay loop muted playsInline className="w-full max-h-[740px] h-full object-cover"/>
    </div>

    // <div className="w-full">
    //   <img src={Map} alt="" className="w-full"/>
    // </div>
    
      // <Swiper
      //   effect="fade" // Эффект появления
      //   centeredSlides={true}
      //   autoplay={{
      //     delay: 4500,
      //     disableOnInteraction: false,
      //   }}
      //   modules={[Autoplay, EffectFade]}
      //   className="heroSwiper"
      //   // data-aos = "zoom-in" 
      // >
      //   {carouselImages?.map((image, i) => (
      //     <SwiperSlide key={i}>
      //       <div className="w-full max-h-[630px]" >
      //       <LazyLoadImage
      //           className="w-full h-full"
      //           alt="BUZ GROUP carousel images"
      //           effect="blur"
      //           width="100%"
      //           src={`https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/${image}`}
      //            />
      //         <img loading="lazy" src={`https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/${image}`} alt="BUZ GROUP carousel images" className="w-full h-full" />
      //       </div>
      //     </SwiperSlide>
      //   ))}
      // </Swiper>
    }
    
    </>
  );
};

export default Hero;
