import React, { useEffect, useState } from 'react'
import { GrFormAdd, GrFormSearch, GrFormSubtract } from "react-icons/gr";
import { CgMenuGridR } from "react-icons/cg";
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryIDlist, getCatList, getProductsList, getSubCategoryIDlist } from '../action/MainAction';
import { Link } from 'react-router-dom';
import ResponsivePagination from 'react-responsive-pagination';

import axios from 'axios';
import { isLoadingFunc, isLoadingStart, productsListFunc } from '../redux/MainReducer';
import Loading from './Loading';
import i18next from 'i18next';
import Accordion from './common/Accordion/Accordion';
import { useTranslation } from "react-i18next";
import rightVector from '../images/rightVector.svg'
import leftVector from '../images/leftVector.svg'

const Products = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation()

    // Вызов переменной содеращий данные о партеров и пременная для loader
    const catListValue = useSelector((state) => state.Data.catListValue);
    const loading = useSelector((state) => state.Data.loading);

    useEffect(() => {
      window.scrollTo(0,0)
      dispatch(isLoadingStart());

      // Вызов api/categories/az/
      if (i18next.language !== "dev") {
        dispatch(getCatList(i18next.language.toLowerCase()));
      }
      // Вызов api/products/
      dispatch(getProductsList());
    }, [dispatch, i18next.language]);

  
    // Для toogle при нажатии на категорию при наличии подкатегории
    const [expandedCategories, setExpandedCategories] = useState({});

    const toggleSubcategories = (id) => {
      setExpandedCategories((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
      }));
    };

  
    // Вызов переменной содержащий данные о списке товаров 
    const productsListValue = useSelector((state) => state.Data.productsListValue);

    // Orxan's code for burger menu
    const [catalog, setCatalog] = useState(false)

    const handleCatalog = () =>{
        setCatalog(!catalog)
    }

   // Вызова списка товаров по категории
const callCategoryIDlist =(id)=>{
  dispatch(isLoadingStart()); // Устанавливаем загрузку при нажатии на числа пагинации
  setCatalog(!catalog) // Закрываем burger menu при вызове функции
  
  // Вызов Функции в MainAction список товаров по категориям
  
  dispatch(getCategoryIDlist(id))
  
}

// Вызова списка товаров по ПОДкатегории
const callSubCategoryIDlist =(id)=>{
  dispatch(isLoadingStart()); // Устанавливаем загрузку при нажатии на числа пагинации

  setCatalog(!catalog) // Закрываем burger menu при вызове функции
// Вызов Функции в MainAction список товаров по ПОДкатегориям
  dispatch(getSubCategoryIDlist(id))
  
}
   
// Pagination count 
const [currentPage, setCurrentPage] = useState(1);
const totalPages = Math.ceil(productsListValue?.count/12)


// Смена показа продуктов при нажатии на числа пагинации
const handlePageClick = async (page) => {
  dispatch(isLoadingStart()); // Устанавливаем загрузку при нажатии на числа пагинации
  console.log("Загрузка начата"); // Для теста отслеживания состояния 

  window.scrollTo({ top: 0, behavior: "smooth" }); // Скроллим наверх
  setCurrentPage(page); // Устанавливаем текущую страницу

  try {
    if (page === 1) {
      
      setTimeout(()=>{
        dispatch(getProductsList()); // Если первая страница, вызываем готовый action

      }, 1000)
      dispatch(isLoadingStart()); // Устанавливаем загрузку
    } else if (productsListValue?.next || productsListValue?.previous) {
      // Если есть следующая или предыдущая страница, запрашиваем её
      const response = await axios.get(`https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/api/products/?page=${page}`);
      setTimeout(()=>{
        dispatch(productsListFunc(response.data));
      }, 1000)
    }
  } catch (error) {
    console.error("Ошибка при загрузке:", error);
    // Если ошибка при нажатии при вызове, вызываем готовый action
    dispatch(productsListFunc([]));
  } finally {
    setTimeout(() => {
      dispatch(isLoadingFunc()); // Устанавливаем loading в false через 2 секунды
    }, 1000); 
    console.log("Загрузка завершена"); // Отключаем загрузку
  }
};


// Accordion test

  const [openId, setOpenId] = useState(null);

  const toggleCategory = (id) => {
    setOpenId((prevId) => (prevId === id ? null : id));
  };

  // Используем код языка для отображения url www.site.com/en/...
  const lang = i18next?.language.toLowerCase()

  

  return (
    <div className="relative overflow-hidden">
      <div className='absolute top-0 -z-10 hidden sm:block sm:-right-36 lg:right-0'>
        <img src={rightVector} alt="" />
      </div>
      <div className='absolute top-0 -z-10 hidden sm:block sm:-left-36 lg:left-0'>
        <img src={leftVector} alt="" />
      </div>
      <div className="max-w-[1440px] mx-auto py-10 sm:py-24">
        {/* burger menu */}
        <div className="block md:hidden mb-5">
          <div className="max-w-[580px] mx-auto flex items-center justify-between px-2 h-10">
            <div className="max-w-[200px] bg-[#5f6b7c] rounded caret-white flex justify-between items-center">
              <input
                type="search"
                placeholder={t("keyword")}
                className="w-full p-2 bg-[#5f6b7c] rounded outline-none"
              />
              <div className="w-9 h-9 rounded-r">
                <GrFormSearch size={35} className="text-white" />
              </div>
            </div>
            <div className="relative">
              <div
                className="flex justify-between items-center"
                onClick={handleCatalog}
              >
                <CgMenuGridR size={35} className="text-white" />
              </div>
              <ul
                className={`absolute w-[300px] top-10 right-0 text-white z-20 cursor-pointer border bg-[#5f6b7c] transition-all duration-300 ease-in-out rounded
                  ${catalog
                    ? "top-10 right-0"
                    : "absolute opacity-0 -right-[500px]"}`}
              >
                {catListValue?.map((item) => (
                  <li
                    key={item?.id}
                    className="px-3 py-1 border border-x-0 border-t-0 border-[#eaeaea] "
                  >
                    <div className="flex justify-between items-center">
                      <span onClick={() => callCategoryIDlist(item?.id)}>
                        {item?.title}
                      </span>
                      {item?.subcategories?.length > 0 && (
                        <button
                          onClick={() => toggleSubcategories(item.id)}
                          className="text-white text-xl ml-2"
                        >
                          {expandedCategories[item.id] ? (
                            <GrFormSubtract />
                          ) : (
                            <GrFormAdd />
                          )}
                        </button>
                      )}
                    </div>
                    <div
                      className={`overflow-hidden transition-max-height duration-700 ease-in-out ${
                        expandedCategories[item.id] ? "max-h-96" : "max-h-0"
                      }`}
                    >
                      <ul className="pl-4 mt-2">
                        {item.subcategories.map((subcategory) => (
                          <li
                            key={subcategory.id}
                            className="px-2 py-1 border border-x-0 border-b-0 border-[#eaeaea]"
                            onClick={() =>
                              callSubCategoryIDlist(subcategory?.id)
                            }
                          >
                            {subcategory?.title}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Главная страница */}
        <div className="max-w-[580px] md:max-w-[1284px] font-dm text-white px-3 mx-auto flex justify-between md:gap-x-14">
          {/* Секция отображения категорий и подкатегорий */}
          <div data-aos="fade-right">
            <div className="md:w-[280px] lg:w-[320px] w-full hidden md:block">
              {/* Поле поиска */}
              {/* Временно не активное  */}
              {/* <div className="border border-x-0 border-t-0 border-[#eaeaea] bg-[#5f6b7c] flex justify-between items-center px-2">
                <input
                  type="text"
                  placeholder={t("keyword")}
                  className="w-full py-1 outline-none placeholder:text-white bg-[#5f6b7c] bg-bl px-2"
                />
                <div className="w-9 h-9 ml-1 rounded-r flex justify-center items-center cursor-pointer">
                  <GrFormSearch size={30} className="text-white" />
                </div>
              </div> */}

              {/* Список категорий */}
              <div className="categories">
                <ul className="cursor-pointer text-roboto">
                  {catListValue?.map((category) => (
                    <Accordion
                      key={category.id}
                      item={category}
                      isOpen={openId === category.id}
                      onClickAccordion={toggleCategory}
                      callCategoryIDlist={(id) => callCategoryIDlist(id)}
                      callSubCategoryIDlist={(id) => callSubCategoryIDlist(id)}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Секция отображения товаров */}
          {loading ? (
            <Loading />
          ) : (
            <div>
              <div className="max-w-[894px] grid grid-cols-2 gap-y-10 gap-x-[30px] lg:grid-cols-3">
                {!productsListValue?.results?.length ? (
                  <p>Not found this product :( </p>
                ) : (
                  productsListValue?.results?.map((data) => (
                    <div
                      key={data.id}
                      className="group flex flex-col items-center rounded-[10px] border-[#eaeaea] bg-gradient-to-b from-[#FFFFFF]/80 to-[#404040] 
                                hover:bg-gradient-to-b hover:from-[#FFFFFF]/90 hover:to-[#404040] transition duration-700 ease-in-out"
                    >
                      <div className="block left-auto">
                        <div className="pb-6 mx-6 flex flex-col items-end relative">
                          {data.is_new && data.is_discount ? (
                            <div className="w-full flex justify-between items-center z-10 relative">
                              <div className="lg:text-xl text-center w-12 xs:w-14 md:w-[76px] rounded text-white bg-[#44d62c]">
                                NEW
                              </div>
                              <div className="lg:text-xl text-center w-12 xs:w-14 md:w-[76px] rounded text-white bg-[#5e2d7a]">
                                -{Math.floor(data.discount_amount)}%
                              </div>
                            </div>
                          ) : data.is_new || data.is_discount ? (
                            <span
                              className={`lg:text-xl text-center w-12 xs:w-14 md:w-[76px] rounded text-white z-10 relative ${
                                data.is_new ? "bg-[#44d62c]" : "bg-[#5e2d7a]"
                              }`}
                            >
                              {data.is_new
                                ? "NEW"
                                : `-${Math.floor(data.discount_amount)}%`}
                            </span>
                          ) : (
                            <span className="block h-6"></span>
                          )}
                          <div className="group-hover:scale-105 ease-in-out duration-700 z-0 max-w-[240px]">
                            <img
                              loading="lazy"
                              src={data?.cover_image}
                              alt={data.title}
                              className="h-full w-full object-cover object-center"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-full flex flex-col justify-start gap-y-2 px-4">
                        <h3 className=" text-white font-taviraj sm:font-bold sm:text-md overflow-hidden">
                          {data?.title}
                        </h3>
                        <Link
                          to={`/${lang}/singleprod/${
                            data?.id
                          }/${encodeURIComponent(
                            data?.title.replace(/\s+/g, "-").toLowerCase()
                          )}`}
                          className=""
                        >
                          <button className="w-full py-2 bg-lightGreen rounded-3xl text-white center my-3 font-dm">
                            {t("viewDetails")}
                          </button>
                        </Link>
                      </div>
                    </div>
                  ))
                )}
              </div>
              {/* Pagination */}
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={(page) => handlePageClick(page)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Products