import React, { useEffect } from "react";
import SingleProdsMain from "./SingleProdsMain";
import { useDispatch, useSelector } from "react-redux";
import { getMostSelList } from "../action/MainAction";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import rightVector from '../images/rightVector.svg'
import leftVector from '../images/leftVector.svg'


const SingleProd = () => {
  
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Вызов переменной содержащий данные много продоваемых продуктов 
  const mostSelListValue = useSelector(
    (state) => state.Data.mostSelListValue
  );

  // Вызов api/most-selling/
  useEffect(() => {
    window.scrollTo(0,0)
    dispatch(getMostSelList());
  }, [dispatch]);

  
    // Используем код языка для отображения url www.site.com/en/...
    const lang = i18next?.language.toLowerCase()
    

  return ( 
    <div className="relative overflow-hidden">
      <div className='absolute top-0 -z-10 hidden sm:block sm:-right-36 lg:right-0'>
        <img src={rightVector} alt="" />
      </div>
      <div className='absolute top-0 -z-10 hidden sm:block sm:-left-36 lg:left-0'>
        <img src={leftVector} alt="" />
      </div>
      <SingleProdsMain />
      <div className="mb-[30px]">
        <div className="max-w-[1324px] mx-auto text-white px-3">
          <h6 className="text-3xl md:text-4xl">{t('bestSellers')}</h6>
          <div className="relative">
           
              <Swiper
                slidesPerView={2}
                spaceBetween={10}
                pagination={{
                  clickable: true,
                }}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  676: {
                    slidesPerView: 3,
                  },
                  980: {
                    slidesPerView: 4,
                  },
                  1240: {
                    slidesPerView: 5,
                  },
                  1660: {
                    slidesPerView: 6,
                  },
                }}
                modules={[Pagination, Navigation, Autoplay]}
                className="mySwiper "
              >
                {mostSelListValue?.results?.map((data, i) => (
                  <SwiperSlide key={i}>
                    {/* <Link to={`/${lang}/singleprod/${data?.id}/${encodeURIComponent(data?.title.replace(/\s+/g, "-").toLowerCase())}`} */}
                    <Link  to={`/${lang}/singleprod/${data?.product?.id}/${encodeURIComponent(data?.product?.title.replace(/\s+/g, "-").toLowerCase())}`
                      
                    }>
                      <div className="bg-gradient-to-b from-[#FFFFFF]/80 to-[#404040] hover:bg-gradient-to-b hover:from-[#FFFFFF]/90 hover:to-[#404040] 
                                      transition duration-700 ease-in-out min-h-[240px] p-3 mt-5 rounded-lg space-y-3 flex flex-col items-center">
                        <div className="max-w-[200px]">
                          <img
                            src={data?.product?.cover_image}
                            alt={`Buz GROUP ${data?.product?.title}`}
                            className="w-full"
                          />
                        </div>
                        <p className="font-bold text-[#141130] leading-6">
                          {data?.product?.title}
                        </p>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default SingleProd;
