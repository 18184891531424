import React from 'react'
import Contact from '../components/Contact'
import { Helmet } from 'react-helmet'
import titles from './titles/titles'

const ContactPage = () => {
  return (
    <div>
        <Helmet>
          <title>
            {titles.contactsTit}
          </title>
        </Helmet>
        <Contact/>
    </div>
  )
}

export default ContactPage