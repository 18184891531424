import React from 'react'
import Hero from '../components/Hero'
import HomeGrids from '../components/HomeGrids'
import NewsHome from '../components/NewsHome'
import PartnersSlider from '../components/PartnersSlider'
import { Helmet } from 'react-helmet'
import titles from './titles/titles'
import ParticlesBackground from '../components/common/Sparticles/ParticlesBackground'
import { useDispatch, useSelector } from 'react-redux'
import { isLoadingStart } from '../redux/MainReducer'
import Loading from '../components/Loading'
import rightVector from '../images/rightVector.svg'
import leftVector from '../images/leftVector.svg'


const Home = () => { 


  return (
    <div className='relative overflow-hidden'>  
      <div className='absolute top-[1280px] -z-10 hidden sm:block sm:-right-36 lg:right-0'>
        <img src={rightVector} alt="" />
      </div>
      <div className='absolute top-[1280px] -z-10 hidden sm:block sm:-left-36 lg:left-0'>
        <img src={leftVector} alt="" />
      </div>
      <Helmet>
        <title>
          {titles.homeTit}
        </title>
      </Helmet>
        {/* <ParticlesBackground/> */}
        <Hero/>
        <HomeGrids/>
        <PartnersSlider/> 
        <NewsHome/>
    </div>
  )
}

export default Home