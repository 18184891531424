import { useEffect, useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { getContacts } from '../action/MainAction';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Loading from './Loading'
import axios from 'axios';
import Swal from 'sweetalert2';
import { isLoadingStart } from '../redux/MainReducer';
import rightVector from '../images/rightVector.svg'
import leftVector from '../images/leftVector.svg'


const Contact = () => {
  const [inpName, setInpName] = useState("");
  const [inpLastName, setInpLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [emailInp, setEmailInp] = useState("");
  const [text, setText] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  

  const nameChange = (e) => {
    setInpName(e.target.value);
  };
  const lastNameChange=(e)=>{
      setInpLastName(e.target.value)
  }
  const phoneChange=(value)=>{
      setPhone(value)
  }
  const emailChange=(e)=>{
      setEmailInp(e.target.value)
  }
  const textChange=(e)=>{
      setText(e.target.value) 
  }
  const checkboxChange = (e) => {
    setIsChecked(e.target.checked);
   
  };

  const dispatch = useDispatch()

  const { t } = useTranslation();

  // Вызов переменной содержащий данные о контактов
  const contactsValue = useSelector(state=>state.Data.contactsValue)

  // Вызов api/contacts/az/
  useEffect(() => {
    window.scrollTo(0,0)
    dispatch(isLoadingStart())
    if (i18next.language!=="dev") {
      dispatch(getContacts(i18next.language.toLowerCase()));
    }
  }, [dispatch, i18next.language]); 

  const loading= useSelector(state=>state.Data.loading)

  const [isLoading, setIsLoading] = useState(false)

  // Отправка данных на сервер при клике на send 
  const sendMessage = (e) => {
    e.preventDefault();
    setIsLoading(true)
  
    if (!isChecked) {
      Swal.fire("Please check the box!"); 
    } else {
      const fetchData = async () => {
        const data = {
          email: emailInp,
          comment: text,
          first_name: inpName,
          last_name: inpLastName,
          phone_number: phone,
        };
  
        try {
          const response = await axios.post(
            "https://buzgroupapi-eyb7ffhde4hvh4ex.germanywestcentral-01.azurewebsites.net/api/send-client-email/",
            data, // passing data in request
            {
              headers: {
                "Content-Type": "application/json", // specify that we are sending JSON
              },
            }
          );
          if (response.status === 200) {
            setIsLoading(false)
            Swal.fire("Success!", "Message sent successfully!", "success"); 
            
            setInpName('')
            setInpLastName('')
            setPhone('')
            setEmailInp('')
            setText('')
            setIsChecked(false)
          }
  
          // Handle server response
         
        } catch (error) {
          Swal.fire("Error", "Something went wrong, please try again.", "error"); 
          console.error(error);
        }
      };
      fetchData();
    }
  };
  
  
  
 
  return (
    <div className='relative overflow-hidden'>
      <div className='absolute top-0 -z-10 hidden sm:block sm:-right-36 lg:right-0'>
        <img src={rightVector} alt="" />
      </div>
      <div className='absolute top-0 -z-10 hidden sm:block sm:-left-36 lg:left-0'>
        <img src={leftVector} alt="" />
      </div>
    {
      loading?<Loading/>:(
        <div>
            <div className="bg-gradient-to-b from-[#FFFFFF]/40 to-[#A6A6A6]/10 py-[82px]">
            <div className="max-w-[1200px] mx-auto text-white">
                <h3 className="text-center text-[32px] font-taviraj">{t('contactUs')}</h3>

            <div className="flex flex-wrap justify-center lg:justify-between gap-y-4 mt-[70px]">
              <div className="w-[195px] sm:w-[260px] max-h-[260px] flex flex-col items-center px-3">
                <div className="bg-[#FFFFFF33] p-6 rounded-full">
                  <svg className="p-1" width="50" height="51" viewBox="0 0 50 51" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_304_5941)">
                      <path
                        d="M25 0.5C14.5801 0.5 6.25 8.83008 6.25 19.25C6.25 22.8535 7.32422 25.6367 9.18945 28.459L23.0273 49.4551C23.4473 50.0898 24.1699 50.5 25 50.5C25.8301 50.5 26.5625 50.0801 26.9727 49.4551L40.8105 28.459C42.6758 25.6367 43.75 22.8535 43.75 19.25C43.75 8.83008 35.4199 0.5 25 0.5ZM25 29.6602C19.248 29.6602 14.5801 24.9922 14.5801 19.2305C14.5801 13.4688 19.248 8.80078 25 8.80078C30.752 8.80078 35.4199 13.4688 35.4199 19.2305C35.4199 24.9922 30.752 29.6602 25 29.6602ZM25 13C21.543 13 18.75 15.793 18.75 19.25C18.75 22.707 21.543 25.5 25 25.5C28.457 25.5 31.25 22.707 31.25 19.25C31.25 15.793 28.457 13 25 13Z"
                        fill="white" />
                    </g>
                    <defs>
                      <clipPath id="clip0_304_5941">
                        <rect width="50" height="50" fill="white" transform="translate(0 0.5)" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div>
                  <h6 className="text-xl font-dm font-bold text-center py-2">{t('address')}</h6>
                  {
                    contactsValue[0]?.addresses.map((data, i)=>(
                      <p key={i} className="text-center font-taviraj">{data}</p>

                    ))
                  }
                </div>
              </div>
              <div className="w-[195px] sm:w-[260px] flex flex-col items-center px-3">
                <div className="bg-[#FFFFFF33] p-6 rounded-full">
                  <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.4365 2.40235C15.6846 0.585942 13.7021 -0.380855 11.8076 0.136723L3.21387 2.48047C1.51465 2.94922 0.333008 4.49219 0.333008 6.25C0.333008 30.4102 19.9229 50 44.083 50C45.8408 50 47.3838 48.8184 47.8525 47.1191L50.1963 38.5254C50.7139 36.6309 49.7471 34.6484 47.9307 33.8965L38.5557 29.9902C36.9639 29.3262 35.1182 29.7852 34.0342 31.1231L30.0889 35.9375C23.2139 32.6856 17.6475 27.1191 14.3955 20.2441L19.21 16.3086C20.5479 15.2148 21.0068 13.3789 20.3428 11.7871L16.4365 2.41211V2.40235Z" fill="white"/>
                  </svg>
                </div>
                <div>
                  <h6 className="text-xl font-dm font-bold text-center py-2">{t('contactUs')}</h6>
                  {
                    contactsValue[0]?.phone_numbers?.map((data, i)=>(
                      <p key={i} className="text-center font-taviraj">{data}</p>
                    ))
                  }
                  
                </div>
              </div>
              <div className="w-[195px] sm:w-[260px] flex flex-col items-center px-3">
                <div className="bg-[#FFFFFF33] p-6 rounded-full">
                <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_168_18200)">
                <path d="M48.0713 6.25096C47.8583 6.22901 47.6436 6.22901 47.4307 6.25096H3.68066C3.40026 6.25528 3.12172 6.29732 2.85254 6.37596L25.4307 28.8603L48.0713 6.25096Z" fill="white"/>
                <path d="M50.3818 8.42285L27.6318 31.0791C27.0463 31.6611 26.2543 31.9878 25.4287 31.9878C24.6031 31.9878 23.8111 31.6611 23.2256 31.0791L0.678711 8.59473C0.609398 8.84948 0.572646 9.11199 0.569336 9.37598V40.626C0.569336 41.4548 0.898576 42.2496 1.48463 42.8357C2.07068 43.4217 2.86553 43.751 3.69434 43.751H47.4443C48.2731 43.751 49.068 43.4217 49.654 42.8357C50.2401 42.2496 50.5693 41.4548 50.5693 40.626V9.37598C50.5569 9.05044 50.4936 8.72884 50.3818 8.42285ZM5.83496 40.626H3.66309V38.3916L15.0225 27.126L17.2256 29.3291L5.83496 40.626ZM47.4131 40.626H45.2256L33.835 29.3291L36.0381 27.126L47.3975 38.3916L47.4131 40.626Z" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0_168_18200">
                <rect width="50" height="50" fill="white" transform="translate(0.666992)"/>
                </clipPath>
                </defs>
                </svg>
                </div>
                <div>
                  <h6 className="text-xl font-dm font-bold text-center py-2">Email</h6>
                  {
                    contactsValue[0]?.emails?.map((data, i)=>(
                      <p key={i} className="text-center font-taviraj">{data}</p>
                    ))
                  }
                </div>
              </div>
              <div className="w-[195px] sm:w-[260px] flex flex-col items-center px-3">
                <div className="bg-[#FFFFFF33] p-6 rounded-full">
                  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25 0C20.0555 0 15.222 1.46622 11.1108 4.21326C6.99953 6.9603 3.79521 10.8648 1.90302 15.4329C0.0108321 20.0011 -0.484251 25.0277 0.480379 29.8773C1.44501 34.7268 3.82603 39.1814 7.32234 42.6777C10.8187 46.174 15.2732 48.555 20.1228 49.5196C24.9723 50.4843 29.9989 49.9892 34.5671 48.097C39.1353 46.2048 43.0397 43.0005 45.7867 38.8893C48.5338 34.778 50 29.9445 50 25C50 18.3696 47.3661 12.0107 42.6777 7.32233C37.9893 2.63392 31.6304 0 25 0ZM31.25 36.25L22.5 27.5V10H27.5V25L35 32.5L31.25 36.25Z" fill="white"/>
                  </svg>
                </div>
                <div>
                  <h6 className="text-xl font-dm font-bold text-center py-2">{t('workingHours')}</h6>

                    {/* Work days */}
                    {
                      contactsValue[0]?.working_days?.map((data, i)=>(

                        <p key={i} className="text-center font-taviraj">{data}</p>
                      ))
                    }
                 {/* Work house */}
                  {
                    contactsValue[0]?.working_hours?.map((data, i)=>(
                      <p key={i} className="text-center">{data}</p>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-[1240px] mx-auto flex flex-col lg:flex-row items-center lg:items-start justify-between my-16 px-5 gap-10">
          <div className="w-full sm:w-[580px] flex flex-col text-white">
            <h4 className="text-[28px] lg:text-[32px] font-taviraj">{t("anyQuestions")}</h4>
            <p className="text-base lg:text-xl mt-8 font-dm">{t('ifYouHaveQuestions')}</p>
          </div>
          {/* Анкеда заполнения вопросов */}
          <form onSubmit={sendMessage} className="w-full sm:w-[580px] flex flex-col items-start gap-y-6 text-white" >
            <div className="w-full flex flex-col sm:flex-row gap-6">
              <div className="w-full py-2 px-3 border border-x-0 border-t-0 border-[#e4e4e7]">
                {/* Name input */}
                <label htmlFor="name" className="flex items-center gap-x-0.5">{t('name')}<span><svg width="8" height="8" viewBox="0 0 8 8"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.45455 7.40909L3.54545 4.90909L1.43182 6.25L0.886364 5.29545L3.11364 4.13636L0.886364 2.97727L1.43182 2.02273L3.54545 3.36364L3.45455 0.863636H4.54545L4.45455 3.36364L6.56818 2.02273L7.11364 2.97727L4.88636 4.13636L7.11364 5.29545L6.56818 6.25L4.45455 4.90909L4.54545 7.40909H3.45455Z"
                        fill="#F31260" />
                    </svg></span></label>
                <input 
                type="text" 
                id="name" 
                placeholder={t('name')} 
                className="w-full border-none outline-none bg-[#000F24] bg-transparent"
                value={inpName}
                onChange={nameChange}
                required
                
                
                />
              </div>
              <div className="w-full py-2 px-3 border border-x-0 border-t-0 border-[#e4e4e7]">
                <label htmlFor="lastname" className="flex items-center gap-x-0.5">{t('surname')}<span><svg width="8" height="8" viewBox="0 0 8 8"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.45455 7.40909L3.54545 4.90909L1.43182 6.25L0.886364 5.29545L3.11364 4.13636L0.886364 2.97727L1.43182 2.02273L3.54545 3.36364L3.45455 0.863636H4.54545L4.45455 3.36364L6.56818 2.02273L7.11364 2.97727L4.88636 4.13636L7.11364 5.29545L6.56818 6.25L4.45455 4.90909L4.54545 7.40909H3.45455Z"
                        fill="#F31260" />
                    </svg></span></label>
                    {/* Lastname Input */}
                <input type="text" 
                id="lastname" 
                placeholder={t('surname')} 
                className="w-full border-none outline-none bg-[#000F24] bg-transparent"
                value={inpLastName}
                onChange={lastNameChange}
                required
                />
                
              </div>
            </div>
            <div className="w-full flex flex-col sm:flex-row  gap-6">
              <div className="w-full px-3 border border-x-0 border-t-0 border-[#e4e4e7]">
                
                {/* Telefon input  */}
                <div>
                  <label htmlFor="phone" className="flex items-center gap-x-0.5">{t('phone')}<span><svg width="8" height="8"
                      viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.45455 7.40909L3.54545 4.90909L1.43182 6.25L0.886364 5.29545L3.11364 4.13636L0.886364 2.97727L1.43182 2.02273L3.54545 3.36364L3.45455 0.863636H4.54545L4.45455 3.36364L6.56818 2.02273L7.11364 2.97727L4.88636 4.13636L7.11364 5.29545L6.56818 6.25L4.45455 4.90909L4.54545 7.40909H3.45455Z"
                        fill="#F31260" />
                    </svg></span>
                  </label>
                  <PhoneInput
                    placeholder={t('phone')}
                    defaultCountry="az"
                    value={phone}
                    onChange={(phone) => phoneChange(phone)}
                    required
                  />
                </div>
              </div>
              <div className="w-full py-2 px-3 border border-x-0 border-t-0 border-[#e4e4e7]">
                <label htmlFor="email" className="flex items-center gap-x-0.5">E-mail<span>
                  <svg width="8" height="8"
                      viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.45455 7.40909L3.54545 4.90909L1.43182 6.25L0.886364 5.29545L3.11364 4.13636L0.886364 2.97727L1.43182 2.02273L3.54545 3.36364L3.45455 0.863636H4.54545L4.45455 3.36364L6.56818 2.02273L7.11364 2.97727L4.88636 4.13636L7.11364 5.29545L6.56818 6.25L4.45455 4.90909L4.54545 7.40909H3.45455Z"
                        fill="#F31260" />
                    </svg></span></label>
                <input 
                type="email" 
                id="email" 
                placeholder="..." 
                className="w-full border-none outline-none bg-[#000F24] bg-transparent"
                value={emailInp}
                onChange={emailChange} 
                required
                
                />
              </div>
            </div>
            <div className="w-full">
              <label htmlFor="message" className="text-white px-3">{t('messageBox')}</label>
              <textarea 
              id="message" 
              name="message" 
              rows="7" cols="50" 
              placeholder="Text..."
              className="w-full py-1 px-3 outline-none border border-[#e4e4e7] resize-none bg-[#000F24] bg-transparent"
              value={text}
              onChange={textChange}
              required></textarea>
            </div>
            <div className="flex items-start mb-4">
              {/* Checkbox input */}
              <input 
              id="default-checkbox" 
              type="checkbox" 
              value={isChecked}
              className="mt-1.5 text-white"
              onChange={checkboxChange}
              
              
              />
              <label htmlFor="default-checkbox" className="max-w-[500px] ms-2 text-sm text-white leading-6">
                {t('emailConsent')}</label>
            </div>
            <button className="flex text-white font-dm text-sm rounded bg-lightGreen hover:bg-darkGreen border border-lightGreen hover:border-white ease-in-out duration-500 tracking-widest px-20 py-4 sm:px-24 lg:px-[136px] sm:py-5">{t("send")}</button> 
          </form>

        </div>
        <div className="max-w-[1240px] mx-auto flex flex-col lg:flex-row items-center lg:items-start justify-between my-16 px-5 gap-10">
            <div className="w-full sm:w-[580px] flex flex-col text-white">
                <h4 className="text-[28px] lg:text-[32px] font-taviraj">{t('findUsHere')}</h4>
                <p className="text-base lg:text-xl mt-8 font-dm">{contactsValue[0]?.addresses[0]}</p>
            </div>
            <div className="max-w-[790px] w-full lg:w-[690px]">
          <iframe
               src={`https://www.google.com/maps?q=${encodeURIComponent(contactsValue[0]?.addresses[0])}&output=embed`}
                className="w-full h-[400px] border-none"
                allowFullScreen
            ></iframe>
        </div>

        </div>
      </div>


      )
    }
    </div>
  )
}

export default Contact