import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import axios from 'axios';

const countryLanguageMap = {
  RU: 'ru',
  TR: 'tr',
  AZ: 'az',
  GE: 'ka',
  US: 'en',
  GB: 'en',
  KZ: 'kz',  // Новый язык интерфейса
  UZB: 'uzb'  // Новый язык интерфейса
};

const apiLanguageMap = {
  kz: 'en', // Сервер должен получать запросы на английском
  uz: 'en'
};

const setLanguageFromURLorCountry = async () => {
  const storedLang = localStorage.getItem('i18nextLng');
  const pathLang = window.location.pathname.split('/')[1];

  const normalizeLanguage = (lang) => {
    if (!lang) return null;
    const match = lang.match(/^[a-z]{2,3}/i);
    return match ? match[0].toLowerCase() : null;
  };

  let normalizedLang = normalizeLanguage(pathLang) || normalizeLanguage(storedLang);

  if (!normalizedLang) {
    try {
      const response = await axios.get('https://ipapi.co/json/');
      const countryCode = response.data.country_code;
      normalizedLang = countryLanguageMap[countryCode] || 'en';
    } catch (error) {
      console.error("Ошибка при получении геолокации:", error);
      normalizedLang = 'en';
    }
  }

  const apiLang = apiLanguageMap[normalizedLang] || normalizedLang;
  
  

  i18n.changeLanguage(normalizedLang);
  localStorage.setItem('i18nextLng', normalizedLang);
  localStorage.setItem('apiLang', apiLang); // Сохраняем язык API
};


// Инициализация i18n
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['localStorage', 'queryString', 'cookie'], // Сначала проверяем сохраненные значения
      cache: ['localStorage', 'cookie'], // Сохраняем язык в localStorage и cookie
    },
    interpolation: {
      escapeValue: false,
    },
  });

// Вызываем функцию установки языка при первой загрузке
setLanguageFromURLorCountry();

export default i18n;
