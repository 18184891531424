import React from "react";
import News from "../components/News";
import { Helmet } from "react-helmet";
import titles from "./titles/titles";
import ParticlesBackground from "../components/common/Sparticles/ParticlesBackground";

const NewsPage = () => {
  return (
    <>
      <Helmet>
        <title>{titles.newsTit}</title>
      </Helmet>
      {/* <ParticlesBackground /> */}
      <News />
    </>
  );
};

export default NewsPage;
