import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import ProductsPage from "./pages/ProductsPage";
import NewsPage from "./pages/NewsPage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
// import UnderNav from "./components/UnderNav";
import SingleNewsPage from "./pages/SingleNewsPage";
import SingleProdPage from "./pages/SingleProdPage";
import ContactPage from "./pages/ContactPage";
import About from "./pages/About";
import PartnersPage from "./pages/PartnersPage";



function App() {

  return (
    
    <BrowserRouter basename="/">
        <Navbar />
        {/* <UnderNav /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:lang/productspage" element={<ProductsPage />} />
          <Route path="/:lang/singleprod/:id/:productname" element={<SingleProdPage />} />
          <Route path="/:lang/newspage" element={<NewsPage />} />
          <Route path="/:lang/singlenewspage/:id" element={<SingleNewsPage />} /> 
          <Route path="/:lang/partnerspage" element={<PartnersPage />} />
          <Route path="/:lang/about" element={<About />} />
          <Route path="/:lang/contact" element={<ContactPage />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
    </BrowserRouter>
  );
}

export default App;
